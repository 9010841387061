<template>
    <div>
        <template v-if="currentRouteName!=='Onboarding'">
            <AppSidebar/>
        </template>
        <div class="wrapper d-flex flex-column min-vh-100 bg-light">
            <AppHeader />
            <div class="body d-flex flex-column flex-grow-1 px-3">
                <!-- <router-view :search-input="searchInput"/> -->
                <router-view />
            </div>
            <AppFooter/>
        </div>
    </div>
</template>
<script>
import {CContainer} from '@coreui/vue'
import AppFooter from '../components/AppFooter.vue'
import AppHeader from '../components/AppHeader.vue'
import AppSidebar from '../components/navigation/AppSidebar.vue'
import transactionsPopup from "@/components/TransactionsPopup.vue";
import useUserStore from '../stores/userStore';

export default {
    name: 'DefaultLayout',
    components: {
        transactionsPopup,
        AppFooter,
        AppHeader,
        AppSidebar,
        CContainer,
    },
    setup() {
        const userStore = useUserStore();
        userStore.getUserInfo;
        return { userStore };
    },
    data() {
        return {
            // searchInput: null,
            showMobileNav: false
        }
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        }
    },
    methods: {
        // search(searchInput) {
        //     this.searchInput = searchInput;
        // }
    }
}
</script>
