<template>
    <RouterLink :to="item.to" custom v-if="item.to">
        <template v-slot="{ _, navigate, isActive }">
            <CNavItem
                :class="isActive ? `nav_item nav_item_active` : `nav_item`"
                :active="isActive"
                @click="navigate">
                <font-awesome-icon
                    :icon="item.faIcon"
                    size="lg"
                    class="label-icon nav_item_icon" />
                <div>{{ item.name }}</div>
                <CBadge
                    v-if="item.badge"
                    :color="item.badge.color"
                    class="ms-auto"
                    >{{ item.badge.text }}
                </CBadge>
            </CNavItem>
        </template>
    </RouterLink>
    <!-- Full URL -->
    <CNavItem v-else-if="item.toUrl"
              class="nav_item"
              @click="loadUrl(item.toUrl)">
        <font-awesome-icon
            :icon="item.faIcon"
            size="lg"
            class="label-icon nav_item_icon" />
        <div>{{ item.name }}</div>
        <CBadge
            v-if="item.badge"
            :color="item.badge.color"
            class="ms-auto"
        >{{ item.badge.text }}
        </CBadge>
    </CNavItem>
    <!-- Dropdown -->
    <template v-else-if="item.items">
        <CNavGroup>
            <template #togglerContent>
                <font-awesome-icon
                    :icon="item.faIcon"
                    size="lg"
                    class="label-icon nav_item_icon" />
                <div>{{ item.name }}</div>
            </template>
            <template v-for="childItem in item.items">
                <AppSidebarItem :item="childItem" />
            </template>
        </CNavGroup>
    </template>
</template>

<script>
export default {
    name: 'AppSidebarItem',
    props: ['item'],
    methods: {
        loadUrl(url) {
            window.open(url, '_blank').focus()
        }
    }
};
</script>
