<template>
    <CSidebar position="fixed" class="sidebar_nav">
        <CSidebarBrand class="mb-3" @click="this.$router.push('/')">
            <CRow
                class="col-12 clickable align-content-center justify-content-center align-items-center">
                <img
                    alt="Coherent logo"
                    class="sidebar-brand-full"
                    style="height: 100px; width: 100px"
                    src="/images/elephant-circle.svg" />
            </CRow>
        </CSidebarBrand>

        <CSidebarNav class="sidebar_links">
            <template v-for="item in navigationItems">
                <AppSidebarItem :item="item" v-if="!item.hidden"/>
            </template>
        </CSidebarNav>
    </CSidebar>
</template>

<script>
import AppSidebarItem from './AppSidebarItem.vue';
import useUserStore from '../../stores/userStore';

export default {
    setup() {
        const userStore = useUserStore();
        return { userStore };
    },
    name: 'AppSidebar',
    components: {
        AppSidebarItem,
    },
    data() {
        return {
            merchant: null,
            navigationItems: [
                {
                    component: 'CNavItem',
                    hidden: true,
                    name: 'Onboarding',
                    to: '/onboarding',
                    faIcon: 'fa-clipboard-list',
                },
                {
                    component: 'CNavItem',
                    hidden: true,
                    name: 'Partner Actions',
                    to: '/onboarding/new',
                    icon: 'till',
                    faIcon: 'fa-clipboard-list',
                },
                {
                    component: 'CNavItem',
                    name: 'Till',
                    to: '/dashboard/till',
                    faIcon: 'fa-cash-register',
                },
                {
                    component: 'CNavItem',
                    name: 'Direct Debits',
                    to: '/dashboard/direct-debits',
                    faIcon: 'fa-university',
                },
                {
                    component: 'CNavItem',
                    name: 'Transactions',
                    to: '/dashboard/transactions',
                    faIcon: 'fa-rectangle-list',
                },
                {
                    component: 'CNavItem',
                    hidden: true,
                    name: 'Appointments',
                    to: '/dashboard/appointments',
                    faIcon: 'fa-calendar-days',
                },
                {
                    component: 'CNavItem',
                    hidden: true,
                    name: 'Ellie',
                    items: [{
                        component: 'CNavItem',
                        name: 'Rebook',
                        toUrl: 'https://www.notion.so/coherenthealthcare/147b91f00ed380eeb3dde7baeb33a136?v=147b91f00ed380a28fb6000c311bbb12',
                        faIcon: 'fa-arrows-rotate'
                    },{
                        component: 'CNavItem',
                        name: 'New Lead',
                        toUrl: 'https://www.notion.so/coherenthealthcare/147b91f00ed380d7b511e243733d6486?v=147b91f00ed38067bdae000c7cbb0e87',
                        faIcon: 'fa-thumbs-up'
                    },{
                        component: 'CNavItem',
                        name: 'Off Track',
                        toUrl: 'https://www.notion.so/coherenthealthcare/142b91f00ed380ac9b95d49eca3026ae',
                        faIcon: 'fa-calendar-xmark'
                    },{
                        component: 'CNavItem',
                        name: 'Scripts',
                        toUrl: 'https://www.notion.so/coherenthealthcare/Scrips-templates-149b91f00ed38016b511cef5b7cf634b',
                        faIcon: 'fa-scroll'
                    }],
                    faIcon: 'fa-arrow-trend-up',
                },
                {
                    component: 'CNavItem',
                    name: 'Summary',
                    to: '/dashboard/summary',
                    faIcon: 'fa-pie-chart',
                },
                {
                    component: 'CNavItem',
                    name: 'Support',
                    to: '/dashboard/support',
                    faIcon: 'fa-question',
                },
            ]
        };
    },
    computed: {},
    async mounted() {
        const user = await this.userStore.getUserInfo;
        let userRole = user?.role || 'regular';
        let merchantData = user.merchant;
        // Filtering out appointments from the navigation list for all merchants other than the following.
        let appointmentsShowForMerchants = [
            'aecbac00-9f82-4cc1-865f-b4de12f6ba2e',
            '618536d7-1bf2-4a58-85db-3ba419e9099a',
            'eed9766b-ab1d-476e-a460-44798e8723be',
        ];

        if (merchantData) {
            this.merchant = merchantData;

            if (!merchantData?.onboarded) {
                const index = this.navigationItems.findIndex(it => it.name === 'Onboarding')
                this.navigationItems[index].hidden = false;
            }

            if (userRole === 'partner') {
                const index = this.navigationItems.findIndex(it => it.name === 'Partner Actions')
                this.navigationItems[index].hidden = false;
            }

            if (appointmentsShowForMerchants.includes(merchantData.uuid)) {
                const appointmentsIndex = this.navigationItems.findIndex(it => it.name === 'Appointments')
                this.navigationItems[appointmentsIndex].hidden = false;

                const ellieIndex = this.navigationItems.findIndex(it => it.name === 'Ellie')
                this.navigationItems[ellieIndex].hidden = false;
            }
        }
    },
};
</script>

<style lang="scss">
.sidebar_nav {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.sidebar_links {
    font-family: Inter !important;
    cursor: pointer;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-items: center;
    .nav_item {
        display: flex;
        align-items: center;
        justify-content: start;
        color: #747a80;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0;
        width: 100%;

        .nav_item_icon {
            // margin-right: 10px;
            width: 25%;
            filter: invert(50%);
        }
    }
    .nav_item_active {
        color: black !important;

        .nav_item_icon {
            filter: invert(0%);
        }
    }

    .nav-group {
        .nav-group-toggle {
            display: flex;
            align-items: start;
            justify-content: start;
            color: #747a80;
            padding: 1rem 0;
            font-size: 16px;
            font-weight: 700;
            line-height: 21px;
            letter-spacing: 0;
            width: 100%;

            .nav_item_icon {
                // margin-right: 10px;
                width: 30%;
                filter: invert(50%);
            }
        }
    }

    :hover {
        color: black !important;

        .nav_item_icon {
            filter: invert(0%);
        }
    }
}
</style>
