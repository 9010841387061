import axios from 'axios';
import { defineStore } from 'pinia';
import { SERVICE_URL } from '@/serviceResolver';

const useUserStore = defineStore('userStore', {
    state: () => ({
        user: null,
    }),

    getters: {
        async getUserInfo(state) {
            if(!localStorage.getItem('authToken')){
                return null
            }
            if (state.user) {
                return state.user;
            } else {
                const response = await axios.get(`${SERVICE_URL}/user`);
                const data = await response.data.user;
                state.user = data;
                return data;
            }
        },
    },

    actions: {
        setUserInfo(userData) {
            if(!localStorage.getItem('authToken')){
                return null
            }
            if(userData){
                this.user = userData
                return
            }
            axios.get(`${SERVICE_URL}/user`).then((response) => {
                if (response.data.user) {
                    this.user = response.data.user;
                    return response.data.user;
                } else console.log('error');
            });
        },
    },
});

export default useUserStore;
