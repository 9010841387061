import { createRouter, createWebHistory } from 'vue-router';
import DefaultLayout from '../layouts/DefaultLayout.vue';
import { SERVICE_URL } from '@/serviceResolver';

function isUserAuthenticated() {
    return localStorage.getItem('authToken') !== null;
}

const routes = [
    {
        path: '/r',
        redirect: (to) => {
            window.location.replace(`${getAppUrl}/redirect/${to.query.token}`);
        },
    },
    {
        path: '/r/:token',
        redirect: (to) => {
            window.location.replace(
                `${SERVICE_URL}/redirect/${to.params.token}`,
            );
        },
    },
    {
        path: '/cmgs',
        name: 'CMGS Event Payment',
        meta: {
            public: true,
        },
        component: () =>
            import(/* webpackChunkName: "cmgs" */ '../views/landing/CMGS.vue'),
    },
    {
        path: '/link',
        name: 'Coherent Pay Link',
        meta: {
            public: true,
        },
        component: () =>
            import(
                /* webpackChunkName: "link" */ '../views/landing/PayLink.vue'
            ),
    },
    {
        path: '/pay',
        name: 'Payment',
        meta: {
            public: true,
        },
        component: () =>
            import(/* webpackChunkName: "pay" */ '../views/pay/Payment.vue'),
        props: (route) => ({
            token: route.query.token,
            code: route.query.code,
            idToken: route.query.id_token,
        }),
        children: [
            {
                path: '/terms',
                name: 'Terms',
                component: () => import('../views/Till.vue'),
            },
        ],
    },
    {
        path: '/pay/receipt',
        name: 'PaymentReceipt',
        meta: {
            public: true,
        },
        component: () =>
            import(
                /* webpackChunkName: "pay" */ '../views/pay/PaymentReceipt.vue'
            ),
        props: (route) => ({
            sessionId: route.query.sessionId, //Adyen
            redirectResult: route.query.redirectResult, //Adyen
            token: route.query.token, // Redirect or adyen
        }),
    },
    {
        path: '/',
        name: 'Home',
        component: DefaultLayout,
        redirect: '/dashboard/till',
        meta: {
            public: false,
            title: 'Coherent Healthcare',
        },
        children: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                redirect: '/dashboard/till',
            },
            {
                path: '/dashboard/appointments',
                name: 'Appointments',
                component: () =>
                    import('../views/appointments/Appointments.vue'),
            },
            {
                path: '/dashboard/till',
                name: 'Till',
                component: () => import('../views/TillRevamped.vue'),
            },
            {
                path: '/dashboard/direct-debits',
                name: 'Direct Debits',
                component: () => import('../views/DirectDebits.vue'),
            },
            {
                path: '/dashboard/new-direct-debit',
                name: 'New Direct Debit',
                component: () => import('../views/Till.vue'),
                props: (route) => ({
                    type: 'recurring',
                }),
            },
            {
                path: '/dashboard/transactions',
                name: 'Transactions',
                component: () => import('../views/Transactions.vue'),
            },
            {
                path: '/dashboard/summary',
                name: 'Summary',
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'
                    ),
            },
            {
                path: '/dashboard/support',
                name: 'Support',
                component: () => import('../views/Support.vue'),
            },
            {
                path: '/dashboard/mailto/:email',
                name: 'EmailLink',
                beforeEnter: (to, from, next) => {
                    const email = to.params.email;
                    window.location.href = `mailto:${email}`;
                    next(false);
                },
            },
            {
                path: '/dashboard/link/share',
                name: 'Share Pay Link',
                component: () =>
                    import(
                        /* webpackChunkName: "linkshare" */ '../views/SharePayLink.vue'
                    ),
            },
            {
                path: '/onboarding',
                name: 'Onboarding',
                component: () => import('../views/onboarding/Onboarding.vue'),
            },
            {
                path: '/onboarding/new',
                name: 'Partner Actions',
                component: () => import('../views/PartnerActions.vue'),
            },
            {
                path: '/settings',
                name: 'Settings',
                component: () => import('../views/Settings.vue'),
            },
        ],
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
        meta: {
            public: true,
            title: 'Coherent Healthcare - Login',
        },
    },
    {
        path: '/test/pay',
        name: 'TestPayment',
        meta: {
            public: true,
        },
        component: () =>
            import(/* webpackChunkName: "pay" */ '../views/pay/TestPayment.vue'),
        props: (route) => ({
            token: route.query.token,
            code: route.query.code,
            idToken: route.query.id_token,
        }),
        children: [
            {
                path: '/terms',
                name: 'Terms',
                component: () => import('../views/Till.vue'),
            },
        ],
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    const requiresAuth = !to.matched.some((record) => record.meta.public);
    if (requiresAuth && !isUserAuthenticated()) {
        next({ name: 'Login' });
    } else if (to.name === 'Login' && isUserAuthenticated()) {
        next({ name: 'Home' });
    } else {
        next();
    }
});

export default router;
