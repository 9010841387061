<template>
    <div class="overlay" @click="popupClose"></div>
    <div class="transactions-popup">
        <div class="transactions-popup-header pt-5 pb-1">
            <p>{{ renderAmount(item.amount) }}</p>
            <img src="@/assets/images/close.png" class="close clickable" @click="popupClose"/>
        </div>
        <CCol class="m-2">
            <CRow class="justify-content-center">
                <img class="col-5" alt="Payment QR code" :src="qrSrc" v-if="qrSrc && item.status === 'unpaid'"/>
            </CRow>
            <CRow>
                <p class="text-danger" v-if="notificationError">
                    {{ notificationError }}
                </p>
            </CRow>
            <CRow class="pt-2">
                <p class="title">From
                    <template v-if="item.name && item.name.length > 0">
                        {{ item.name }}
                    </template>
                    <template v-else>
                        <CFormInput
                            v-if="isEditingName"
                            v-model="newName"
                            id="name"
                            ref="name"
                            @keyup.enter="updateName()"
                            @blur="updateName()"></CFormInput>
                        <span class="clickable" @click="isEditingName = true; focusInputField('name')" v-if="isEditingName === false">
                            <span style="font-style: italic; border-bottom: dashed 1px; background-color: #efd9a3">
                                Unknown
                                <font-awesome-icon icon="fa-solid fa-pen-to-square" class="ms-1"/>
                            </span>
                        </span>
                    </template>
                </p>
            </CRow>
            <CRow>
                <p class="date">{{ renderDate(item.createdTime) }}</p>
            </CRow>
            <CRow class="justify-content-center">
                <button class="btn btn-sm btn-outline-dark col-6 col-xs-8"
                        type="button"
                        v-if="(item.phone || item.email) && item.status === 'unpaid'"
                        :disabled="isNotifying"
                        @click="notify">
                    <font-awesome-icon icon="fa-strong fa-rotate" class="me-1" :spin="isNotifying"/>
                    {{ notificationButtonText }}
                </button>
            </CRow>
        </CCol>
        <CRow md="8" xs="10" class="mt-3 align-items-center">
            <CCol class="details-key p-1 ps-5">Transfer</CCol>
            <CCol class="details-value p-1 pe-5">{{ item.endToEndId }}</CCol>
        </CRow>
        <CRow md="8" xs="10" class="details align-items-center">
            <CCol class="details-key p-1 ps-5">Note</CCol>
            <CCol class="details-value p-1 pe-5" @click="isEditingNote = true">
                <CFormTextarea
                    v-if="isEditingNote"
                    v-model="item.note"
                    id="note"
                    style="resize: none;"
                    @blur="updateNote()"></CFormTextarea>
                <span v-else>{{ item.note }}</span>
                <font-awesome-icon icon="fa-solid fa-pen-to-square"
                                   v-if="isEditingNote === false"
                                   class="ms-1"/>
            </CCol>
        </CRow>
        <CRow md="8" xs="10"  class="details align-items-center">
            <CCol class="details-key p-1 ps-5">Amount</CCol>
            <CCol class="details-value p-1 pe-5">{{ renderAmount(item.amount) }}</CCol>
        </CRow>
        <CRow md="8" xs="10"  class="align-items-center"
              v-if="(item.phone && item.phone.length > 0) || (item.email && item.email.length > 0)">
            <CCol class="details-key p-1 ps-5">Sent via</CCol>
            <CCol class="details-value p-1 pe-5">
                <font-awesome-icon
                    @click="isPhoneDisplayed = !isPhoneDisplayed"
                    icon="fa fa-sms" size="xl" class="ms-1"
                    v-if="item.phone && item.phone.length > 0"/>
                <font-awesome-icon
                    @click="isEmailDisplayed = !isEmailDisplayed"
                    icon="fa fa-at" size="xl" class="ms-1"
                    v-if="item.email && item.email.length > 0"/>
                <CCol v-if="isEmailDisplayed">{{item.email}}</CCol>
                <CCol v-if="isPhoneDisplayed">{{item.phone}}</CCol>
            </CCol>
        </CRow>
        <CRow md="8" xs="10"  class="align-items-center">
            <CCol class="details-key p-1 ps-5">Status</CCol>
            <CCol class="details-value p-1 pe-5" v-if="item.status !== 'paid'">
                <CDropdown>
                    <CDropdownToggle class="details-value" size="sm">{{ renderStatus(item.status) }}</CDropdownToggle>
                    <CDropdownMenu>
                        <CDropdownItem class="details-dropdown-option" v-if="item.status === 'void'" href="#" @click="syncWithPaymentGateway('unpaid')">Unpaid
                        </CDropdownItem>
                        <CDropdownItem class="details-dropdown-option" v-else href="#" @click="saveStatus('void')">Void</CDropdownItem>
                    </CDropdownMenu>
                </CDropdown>
            </CCol>
            <CCol class="details-value p-1 pe-5" v-else>{{ renderStatus(item.status) }}</CCol>
        </CRow>
        <CRow md="8" xs="10"  class="align-items-center" v-if="item.paymentMethod">
            <CCol class="details-key p-1 ps-5">Paid By</CCol>
            <CCol class="details-value p-1 pe-5">
                <span>
                    <font-awesome-icon :icon="'fa-solid '+renderPaymentMethodIcon(item.paymentMethod)"></font-awesome-icon>
                    <span class="ms-1">{{ renderPaymentMethodName(item.paymentMethod) }}</span>
                </span>
            </CCol>
        </CRow>
        <CRow md="8" xs="10" class="align-items-center" v-if="availableLocations && availableLocations.length > 0">
            <CCol class="details-key p-1 ps-5">Location</CCol>
            <CCol class="details-value p-1 pe-5">
                <CDropdown>
                    <CDropdownToggle class="details-value" size="sm" :class="item.location ? '' : 'fw-light fst-italic'">
                        {{ item.location ? item.location.name : 'None' }}
                    </CDropdownToggle>
                    <CDropdownMenu>
                        <CDropdownItem class="details-dropdown-option" v-for="location of availableLocations" @click="updateLocation(location)">
                            {{ location.name }}
                        </CDropdownItem>
                        <CDropdownItem class="details-dropdown-option fst-italic" @click="updateLocation()">
                            None
                        </CDropdownItem>
                    </CDropdownMenu>
                </CDropdown>
            </CCol>
        </CRow>
        <CRow class="mb-2 mt-4 px-4 pt-0 popup-footer justify-content-around">
            <CCol class="p-4 pt-0">
                <span class="clickable" @click="printPage()">
                    <font-awesome-icon icon="fa fa-print" class="pe-1" size="lg"/> Print / Save PDF
                </span>
            </CCol>
            <CCol class="p-4 pt-0" style="text-align: right;" v-if="item.status === 'unpaid' && terminalsEnabled">
                <span class="clickable" @click="showTerminals = !showTerminals">
                    <font-awesome-icon icon="fa fa-calculator" class="pe-1" size="lg"/> Terminals
                </span>
            </CCol>
        </CRow>
    </div>
</template>


<script>
import axios from "axios";
import {SERVICE_URL} from "@/serviceResolver";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {focusInputField, renderPaymentMethodIcon, renderPaymentMethodName, renderStatus} from "../tools/util";
import TerminalSelector from "@/components/terminal/TerminalSelector.vue";

export default {
    name: 'TransactionsPopup',
    components: {TerminalSelector, FontAwesomeIcon},
    props: ['currency', 'item'],
    emits: ['popupClose', 'updated'],
    data() {
        return {
            availableLocations: null,
            newName: null,
            identity: null,
            isEditingName: false,
            isEditingLocation: false,
            isEditingNote: false,
            isNotifying: false,
            isPhoneDisplayed: false,
            isEmailDisplayed: false,
            notificationButtonText: 'Resend Request',
            notificationError: null,
            qrSrc: null,
            showTerminals: false,
            terminalsEnabled: false
        }
    },
    mounted() {
        this.fetchIdentity()
        this.fetchLocations()
        this.fetchQrCode()
    },
    methods: {
        focusInputField,
        renderPaymentMethodIcon,
        renderPaymentMethodName,
        renderStatus,
        fetchIdentity() {
            axios.get(`${SERVICE_URL}/user`)
                .then((response) => {
                    this.identity = response.data.user;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        fetchQrCode() {
            axios.get(`${SERVICE_URL}/transaction/${this.item.uuid}/payment/qr`)
                .then((response) => {
                    this.qrSrc = response.data.paymentQrSrc;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        fetchLocations() {
            axios.get(`${SERVICE_URL}/location`)
                .then((response) => {
                    this.availableLocations = response.data
                }).catch((e) => {
                    console.error(e)
                })
        },
        async notify() {
            this.isNotifying = true
            try {
                await axios.post(`${SERVICE_URL}/transaction/${this.item.uuid}/notify`);
                this.notificationButtonText = "Sent!"
            } catch (error) {
                console.error("Error:", error);
                this.notificationError = error;
            } finally {
                setTimeout(() => {
                    this.isNotifying = false;
                    this.notificationButtonText = "Resend Request";
                }, 1000)
            }
        },
        popupClose(e) {
            this.$emit("popupClose", true);
        },
        renderAmount(amount) {
            let formatting_options = {
                style: 'currency',
                currency: this.currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }
            const formatter = new Intl.NumberFormat("en-GB", formatting_options);
            return formatter.format(amount);
        },
        renderDate(createdAt) {
            const date = new Date(createdAt);
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                weekday: 'long'
            };
            return date.toLocaleString('en-GB', options);
        },
        async updateLocation(location) {
            this.isEditingLocation = false
            try {
                await axios.put(`${SERVICE_URL}/transaction/${this.item.uuid}/location`, {
                    locationUuid: location?.uuid
                });
                this.$emit("updated")
            } catch (error) {
                console.error("Error:", error);
            }
        },
        async updateName() {
            this.isEditingName = false
            try {
                await axios.put(`${SERVICE_URL}/transaction/${this.item.uuid}/name`, {
                    name: this.newName
                });
                this.$emit("updated")
            } catch (error) {
                console.error("Error:", error);
            }
        },
        async updateNote() {
            this.isEditingNote = false
            try {
                await axios.put(`${SERVICE_URL}/transaction/${this.item.uuid}/note`, {
                    note: this.item.note
                });
                this.$emit("updated")
            } catch (error) {
                console.error("Error:", error);
            }
        },
        async saveStatus(status) {
            try {
                await axios.put(`${SERVICE_URL}/transaction/${this.item.uuid}/status/${status}`);
                this.$emit("updated")
            } catch (error) {
                console.error("Error:", error);
            }
        },
        async syncWithPaymentGateway(intendedStatus) {
            try {
                await axios.put(`${SERVICE_URL}/transaction/${this.item.uuid}/payment/sync`, {
                    intent: intendedStatus
                });
                this.$emit("updated")
            } catch (error) {
                console.error("Error:", error);
            }
        },
        printPage() {
            window.print(); // Opens the print dialog
        },
    },
}
</script>
<style>
@media print {

    html, body {
        height: 100%;
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;
    }

}

.transactions-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 9999;
    border-radius: 20px;
}

@media (max-width: 576px) {
    .transactions-popup {
        width: 75%;
    }
}

@media (min-width: 992px) {
    .transactions-popup {
        width: 35%;
    }
}

.transactions-popup-header {
    background: #0164AF;
    width: 100%;
    border-radius: 20px;
    position: relative;
}

.transactions-popup-header h3 {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
    line-height: 64px;
    padding: 0 24px;
    width: 100%;
}

.transactions-popup-header .close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 24px;
}

.transactions-popup-header .logo {
    position: absolute;
    bottom: -37px;
    left: calc(50% - 37px);
    width: 74px;
    height: 74px;
    border-radius: 100%;
    border: solid 3px #fff;
}

.transactions-popup-header p {
    color: #fff;
    text-align: center;
    font-size: 56px;
    font-weight: 800;
}

.transactions-popup .title {
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: 0px;
}

.transactions-popup .date {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;

    letter-spacing: 0px;
    text-align: center;
}

.transactions-popup .status {
    width: 150px;
    margin: 0 auto;
}

.transactions-popup .details-key {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    text-align: left;
    color: #747A80;
}

.transactions-popup .details-value {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: right;
}

.transactions-popup .details-dropdown-option {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
}

.transactions-popup .popup-footer {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
}
</style>
