<template>
    <Loader v-if="loader"/>
    <CHeader position="sticky">
        <CContainer fluid>
            <CContainer fluid>
                <CRow class="mt-2 justify-content-between">
                    <CCol class="p-0">
                        <CDropdown class="mobile-nav d-sm-none">
                            <CDropdownToggle :caret="false">
                                <button class="btn btn-light">
                                    <font-awesome-icon icon="fa-solid fa-bars"></font-awesome-icon>
                                </button>
                            </CDropdownToggle>
                            <CDropdownMenu>
                                <template v-for="navigationItem in navigationItems">
                                    <template v-if="!navigationItem.hidden">
                                        <CDropdownItem v-if="navigationItem.to">
                                            <CNavItem>
                                                <CNavLink :href="navigationItem.to">
                                                <span>
                                                     <font-awesome-icon
                                                         :icon="navigationItem.faIcon"
                                                         class="label-icon nav_item_icon" />
                                                      {{navigationItem.name}}
                                                </span>
                                                </CNavLink>
                                            </CNavItem>
                                        </CDropdownItem>
                                        <CDropdownItem v-for="childItem in navigationItem.items" v-else-if="navigationItem.items">
                                            <CNavItem>
                                                <CNavLink :href="childItem.to" v-if="childItem.to">
                                            <span>
                                                <font-awesome-icon
                                                    :icon="childItem.faIcon"
                                                    class="label-icon nav_item_icon" />
                                                {{childItem.name}}
                                            </span>
                                                </CNavLink>
                                                <CNavLink v-else-if="childItem.toUrl"
                                                          class="nav_item"
                                                          @click="loadUrl(childItem.toUrl)">
                                            <span>
                                                <font-awesome-icon
                                                    :icon="childItem.faIcon"
                                                    class="label-icon nav_item_icon" />
                                                {{ navigationItem.name }} - {{ childItem.name }}
                                            </span>
                                                </CNavLink>
                                            </CNavItem>
                                        </CDropdownItem>
                                    </template>
                                </template>
                            </CDropdownMenu>
                        </CDropdown>
                        <div class="d-inline-flex align-items-center header-title">
                            <AppBreadcrumb/>
                        </div>
                    </CCol>
                    <CCol class="d-flex justify-content-end align-items-center">
                        <CNavLink
                                  @click="logout()"
                                  class="logout clickable">
                            <svg width="16px" height="12px" viewBox="0 0 15 15" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.5 7.5L10.5 10.75M13.5 7.5L10.5 4.5M13.5 7.5L4 7.5M8 13.5H1.5L1.5 1.5L8 1.5"
                                      stroke="#000000"/>
                            </svg>
                        </CNavLink>
                    </CCol>
                </CRow>
            </CContainer>
        </CContainer>
        <!-- <CHeaderDivider /> -->
    </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import axios from "axios";
import {useRouter} from "vue-router";
import {SERVICE_URL} from "@/serviceResolver";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: 'AppHeader',
    emits: ['search'],
    props: ['showSearchBar'],
    components: {
        FontAwesomeIcon,
        AppBreadcrumb,
    },
    data() {
        return {
            loader: false,
            navigationItems: [
                {
                    component: 'CNavItem',
                    hidden: true,
                    name: 'Onboarding',
                    to: '/onboarding',
                    faIcon: 'fa-clipboard-list',
                },
                {
                    component: 'CNavItem',
                    hidden: true,
                    name: 'Partner Actions',
                    to: '/onboarding/new',
                    icon: 'till',
                    faIcon: 'fa-clipboard-list',
                },
                {
                    component: 'CNavItem',
                    name: 'Till',
                    to: '/dashboard/till',
                    faIcon: 'fa-cash-register',
                },
                {
                    component: 'CNavItem',
                    name: 'Direct Debits',
                    to: '/dashboard/direct-debits',
                    faIcon: 'fa-university',
                },
                {
                    component: 'CNavItem',
                    name: 'Transactions',
                    to: '/dashboard/transactions',
                    faIcon: 'fa-rectangle-list',
                },
                {
                    component: 'CNavItem',
                    hidden: true,
                    name: 'Appointments',
                    to: '/dashboard/appointments',
                    faIcon: 'fa-calendar-days',
                },
                {
                    component: 'CNavItem',
                    hidden: true,
                    name: 'Ellie',
                    items: [{
                        component: 'CNavItem',
                        name: 'Rebook',
                        toUrl: 'https://www.notion.so/coherenthealthcare/147b91f00ed380eeb3dde7baeb33a136?v=147b91f00ed380a28fb6000c311bbb12',
                        faIcon: 'fa-arrows-rotate'
                    },{
                        component: 'CNavItem',
                        name: 'New Lead',
                        toUrl: 'https://www.notion.so/coherenthealthcare/147b91f00ed380d7b511e243733d6486?v=147b91f00ed38067bdae000c7cbb0e87',
                        faIcon: 'fa-thumbs-up'
                    },{
                        component: 'CNavItem',
                        name: 'Off Track',
                        toUrl: 'https://www.notion.so/coherenthealthcare/142b91f00ed380ac9b95d49eca3026ae',
                        faIcon: 'fa-calendar-xmark'
                    },{
                        component: 'CNavItem',
                        name: 'Scripts',
                        toUrl: 'https://www.notion.so/coherenthealthcare/Scrips-templates-149b91f00ed38016b511cef5b7cf634b',
                        faIcon: 'fa-scroll'
                    }],
                    faIcon: 'fa-arrow-trend-up',
                },
                {
                    component: 'CNavItem',
                    name: 'Summary',
                    to: '/dashboard/summary',
                    faIcon: 'fa-pie-chart',
                },
                {
                    component: 'CNavItem',
                    name: 'Support',
                    to: '/dashboard/support',
                    faIcon: 'fa-question',
                },
            ]
        }
    },
    setup() {
        const router = useRouter();
        const logout = async () => {
            try {
                await axios.post(`${SERVICE_URL}/user/logout`);
                localStorage.removeItem('authToken');
                router.push({name: 'Login'});
            } catch (error) {
                console.error("Error:", error);
            }
        }

        return {
            logout
        };
    },
    methods: {
        loadUrl(url) {
            window.open(url, '_blank').focus()
        }
    }
}
</script>

<style>
.mobile-nav .sidebar-nav .nav-item  {
    margin-top: 10px;
    margin-bottom: 10px;
}

.mobile-nav .btn {
    border-bottom: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}
</style>